<template>
  <div
    class="flex flex-col max-w-full"
  >
    <loader :loading="loading" :backdrop="true" />
    <div class="h-full boxSize overflow-auto">
      <div class="w-1/2 form-row">
        <div class="form-col">
          <p class="mb-1 font-bold text-sm" for="community_name">
            community name
          </p>
          <text-input
            class="mt-2"
            id="community_name"
            v-model="community_name"
            @change="
              handleChange(
                variables.PROPERTY_LONG_NAME.id_community_setting_value,
                community_name
              )
            "
          />
          <span class="form-hint"
            >this is the full name of your community.</span
          >
          <span v-if="hasError('community_name')" class="form-error">{{
            showError('community_name')
          }}</span>
        </div>
      </div>
      <div class="w-1/2 form-row">
        <div class="form-col">
          <p class="mb-1 font-bold text-sm" for="community_name">
            community nickname
          </p>
            <text-input
              id="community_nickname"
              v-model="community_nickname"
              @change="
                handleChange(
                  variables.PROPERTY_SHORT_NAME.id_community_setting_value,
                  community_nickname
                )
              "
            />
            <span class="form-hint"
              >this is the short name of your community.</span
            >
            <span v-if="hasError('community_nickname')" class="form-error"
              >{{ showError('community_nickname') }}
            </span>
        </div>
      </div>
      <div class="form-row">
        <div class="w-1/2">
          <div class="form-col">
            <label for="email">general email address </label>
            <text-input
              id="email"
              v-model="community_email_address"
              @change="
                handleChange(
                  variables.PROPERTY_EMAIL.id_community_setting_value,
                  community_email_address
                )
              "
            />
            <span class="form-hint"
              >this is the main email address of your community.</span
            >
            <span v-if="hasError('community_email_address')" class="form-error">{{
              showError('community_email_address')
            }}</span>
          </div>
        </div>
        <div class="form-col ">
          <label for="phone">phone number </label>
          <text-input
            id="phone"
            v-model="community_phone_number"
            @change="
              handleChange(
                variables.PROPERTY_PHONE_NUMBER.id_community_setting_value,
                community_phone_number
              )
            "
          />
          <span class="form-hint"
            >this is the primary phone number of your community.</span
          >
          <span v-if="hasError('community_phone_number')" class="form-error">{{
            showError('community_phone_number')
          }}</span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-col">
          <label class="form-section-title">hours of operation</label>
          <hours-of-operations :website-operation-hours="communityHours" no-title disabled></hours-of-operations>
        </div>
      </div>
    </div>
    <modal-footer
      :footer="footer"
      :primary="isDisabled ? {} : updateSettings"
      :tertiary="isDisabled ? {} : cancel"
    ></modal-footer>
  </div>
</template>

<script>
  import AuthMixin from '@/components/auth/AuthMixin'
  import ModalNavigation from '@/mixins/ModalNavigation'
  import NotifyMixin from '@/mixins/NotifyMixin'
  import Loader from '@/components/ui/Loader'
  import ModalFooter from '@/components/ui/modals/ModalFooter'
  import EventBus from '@/utils/EventBus'
  import HoursOfOperations from '@/components/operation_hours/HoursOfOperations';
  import TextInput from '@/components/ui/TextInput';

  export default {
    mixins: [AuthMixin, ModalNavigation, NotifyMixin],
    components: { Loader, ModalFooter, HoursOfOperations, TextInput },
    emits: [
        'currentStep',
    ],
    data() {
      return {
        loading: false,
        community_name: '',
        community_nickname: '',
        community_phone_number: '',
        community_email_address: '',
        variables: {
          PROPERTY_LONG_NAME: {},
          PROPERTY_SHORT_NAME: {},
          PROPERTY_PHONE_NUMBER: {},
          PROPERTY_EMAIL: {},
        },
        conversationVariables: [],
        communityHours: [],
        fieldsToUpdate: [],
        isDisabled: false,
        footer: {
          primaryButton: 'next',
          tertiaryButton: 'cancel'
        },
      }
    },
    validations: {
      community_name: 'required',
      community_nickname: 'required',
      community_phone_number: 'required',
      community_email_address: 'required',
    },
    watch: {
      community: {
        handler: function() {
          if (this.community) {
            this.fetchAllCommunitySettings();
            this.fetchRecord();
          }
        },
        immediate: true
      },
    },
    methods: {
      fetchAllCommunitySettings() {
        this.loading = true;
        this.$dhDataProvider
          .getList('allVariablesSettings', { communityId: this.community?.id })
          .then((res) => {
            this.variables = res
            this.loadConversationVariables(res)
          })
          .catch((error) => this.notifyError(error.message))
          .finally(() => (this.loading = false))
      },
      fetchRecord() {
        this.loading = true;
        if(this.community?.id) {
          this.$dhDataProvider
            .getOne('getHoursOfOperations', {id: this.community.id})
            .then((res) => {
              if (!res) return;
              if (res.length > 0) 
                this.communityHours = res;
            })
            .catch((error) => this.notifyError(error.message))
            .finally(() => (this.loading = false))
        }
      },
      handleChange(id_community_setting_value, setting_value, active=false) {
        const newFieldsToUpdate =
              this.fieldsToUpdate.filter(item => item.id_community_setting_value !== id_community_setting_value);

        this.fieldsToUpdate = [
          ...newFieldsToUpdate,
          { id_community_setting_value, setting_value, active },
        ]
      },
      updateSettings() {
        if(this.loading) {
          return null;
        }
        if (this.validator.passes()) {
           this.$emit('currentStep', 1)
          if (!this.fieldsToUpdate.length) {
            EventBus.emit('next-step');
            return;
          }
          this.isDisabled = true;
          this.loading = true;

          this.$dhDataProvider.update('communitySetting', {id: this.fieldsToUpdate[0].id_community_setting_value,
          data: this.fieldsToUpdate})
          .then((response) => {
            if (response.status === 200) {
              this.loading = false;
              this.notifySuccess('The setting was updated successfully')
            }
          })
          .catch((error) => this.notifyError(error.message))
          .finally(() => {
            this.fieldsToUpdate = [];
            this.fetchAllCommunitySettings();
            this.fetchRecord();
            this.loading = false;
            EventBus.emit('next-step');
            this.isDisabled = false;
          })
        }
      },
      loadConversationVariables(conversationVariables) {
        this.community_name =
          conversationVariables?.PROPERTY_LONG_NAME?.setting_value
        this.community_nickname =
          conversationVariables?.PROPERTY_SHORT_NAME?.setting_value
        this.community_phone_number =
          conversationVariables?.PROPERTY_PHONE_NUMBER?.setting_value
        this.community_email_address =
          conversationVariables?.PROPERTY_EMAIL?.setting_value
      },
      cancel(){
        EventBus.emit('show-modal-confirmation', {
          id: 'dh-setup-wizard',
          title: 'setup wizard',
          confirmationType: 'warning',
        })
        EventBus.once('confirm-dh-setup-wizard', () => {
          this.$router.push({ name: 'bookmarks' });
        })
        EventBus.once('cancel-dh-setup-wizard', () => {
          EventBus.emit('cancel-dh-setup-wizard');
        })
      }
    },
    created() {
      this.initValidator()
      this.fieldsToUpdate = [];
    },

  }
</script>

<style scoped>
.active {
  @apply text-blue-500;
}
.buttonStyle {
  display: inline-block;
}
.boxSize {
  height: 630px;
}
</style>
