<template>
    <div class="h-full flex flex-col">
    <loader :loading="loading" :backdrop="true" />
    <div class="flex flex-1 overflow-auto justify-between">
      <div class="section flex flex-col overflow-auto">
          <div class="form-row mt-3" >
            <p>{{this.description}}</p>
          </div>  
      </div>
      <div class="section flex flex-col">
        <div class="flex justify-between mb-5">
          <p
            class="text-lg font-semibold mt-2 w-full title"
            :class="{ disabledText: !(amenities && amenities.length > 0 && community) }"
          >
            primary amenities:
          </p>
        </div>
        <div class="flex" v-if="!(amenities && amenities.length > 0 && community)">
          <p class="text-sm font-semibold mb-2 w-48">
            no amenities available
          </p>
        </div>
        <div v-else class="flex-1 flex-col text-left overflow-auto mt-3 mb-2">
          <div
            class="my-1 flex"
            v-for="option in amenities"
            :key="option.intent_name"
          >
            <input
              type="checkbox"
              @click="setIntent(option)"
              class="mr-2 w-4 h-4 mt-2"
              :id="option.intent_name"
              :value="option.id_community_intent"
              @change="
                 updateCheckbox({ 
                    target: { value: option.id_community_intent },
                    isIntent: true
                  })
              "
              v-model="amenitiesChecked"   
              :disabled="isDefaultDisabled(option)"
            />
            <div
              :disabled="
                activeOption !== option.intent_name &&
                  intentInUse &&
                  intentInUse.id_community_intent !== option.id_community_intent
              "
              class="border w-full p-1 cursor-pointer focus:outline-none"
              :class="{
                activeSubCat: activeOption === option.intent_name
              }"
            >
              <button
                @click="setIntent(option)"
                class="w-full focus:outline-none text-left pl-1"
                :class="{
                  active: activeOption == option.intent_name
                }"
              >
                {{ option.suggestion_text.toLowerCase() }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="section flex flex-col">
        <div class="flex items-center justify-between mb-6">
          <p v-if="intentInUse && amenities && amenityDetails && community"
            class="text-lg font-semibold mt-2 title"
            :class="{ disabledText: !intentInUse }"
          >
            {{suggestionText}} secondary details
          </p>
          <p v-else
            class="text-lg font-semibold mt-2 title"
            :class="{ disabledText: !(intentInUse && community) }"
          >
            amenity details
          </p>
        </div>
        <div v-if="intentInUse && amenities && amenityDetails && community">
          <div
            class="flex items-center"
            v-for="option in amenityDetails"
            :key="option.intent_name"
          >
            <label class="flex-1 " :for="option.intent_name">
              <input
                type="checkbox"
                class="mr-2 w-4 h-4"
                :id="option.intent_name"
                :value="option.id_community_intent"
                v-model="amenityDetailsChecked"
                @change="
                  updateCheckbox({ 
                    target: { value: option.id_community_intent },
                    isIntent: false
                  })
                "
                :disabled="stateMachineDisableCheckbox()"
              />
              <strong class="text-lg font-thin">{{
                option.suggestion_text
              }}</strong>
            </label>
          </div>
        </div>
        <div class="flex">
          <p v-if="!(intentInUse && community)" class="text-sm font-semibold mb-4 w-48">
            select an amenity to manage the amenity details
          </p>
          <p
            v-if="intentInUse && amenityDetails && amenityDetails.length === 0 && community" 
            class="text-sm font-semibold mb-4 w-48"
          >
            no amenity details for this amenity
          </p>
        </div>
      </div>
    </div>
    <modal-footer 
      :footer="footer" 
      :primary="() => { 
        if(!isDisabled) onSave()
      }" 
      :tertiary="() => { 
        if(!isDisabled) previousStep()
      }"
    >
    </modal-footer>
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import Loader from '@/components/ui/Loader'
import ModalFooter from '@/components/ui/modals/ModalFooter'
import ManageAmenitiesMixin from '@/components/digital_human/ManageAmenitiesMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import EventBus from '@/utils/EventBus'
export default {
  mixins: [AuthMixin, ModalNavigation, NotifyMixin, ManageAmenitiesMixin],
  components: {
    Loader,
    ModalFooter,
  },
   props: {
        description: {
          type: String,
          required: false,
        },
        category: {
            type: Object,
            default() {
                return {}
            },
        },
  },
  emits: [
      'currentStep',
      'previousStep',
  ],
  data() {
    return {
      amenityDetailsChecked: [],
      amenitiesChecked: [],
      unitAmenitiesDisabled: [
      ],
      activeOption: null,
      intentInUse: null,
      intentIdInUse: null,
      subIntentsHasChanged: false,
      isActive: true,
      suggestionText: '',
      isDisabled: false,
      footer: {
        primaryButton: 'next',
        tertiaryButton: 'go back'
        
      },
    }
  },
  watch: {
    community: {
        handler: function() {
            if(this.community)
            {
              this.setCategory(this.category)
            }
        },
        immediate: true
    },
  },
  methods: {
    handleUncheckAmenityModal(amenity) {
        EventBus.emit('show-modal-confirmation', {
        id: 'dh-uncheck-amenity',
        title: 'uncheck amenity details',
        showConfirmation: true,
        confirmationMessage: `This Amenity has active amenity details. Are you sure that you want to uncheck this amenity?`,
        confirmationType: 'warning',
      })
      EventBus.on('confirm-dh-uncheck-amenity', () => {
        if(this.activeOption === amenity?.intent_name){
          amenity.temporarily_disabled = 1
          let subAmenities =  amenity.sub_amenities;
          this.unckeckAllSubAmenities(subAmenities)
        }  
      })
      EventBus.on('cancel-dh-uncheck-amenity', () => {
      this.amenitiesChecked.push(amenity.id_community_intent)
      })
    },
    flipAmenityCheckbox(amenity, idCommunityIntent){
      if(amenity.id_community_intent == idCommunityIntent){
        if(amenity.temporarily_disabled == 0){
          this.checkIfCommunityIntentHasActiveSubIntents(amenity)
        }else{
          amenity.temporarily_disabled = 0
        }
      }
    },
    flipSubAmenityCheckbox(subAmenities, idCommunityIntent){
      for (let i = 0; i < subAmenities.length; i++) { 
          if(subAmenities[i].id_community_intent == idCommunityIntent){
            if(subAmenities[i].temporarily_disabled == 1){
              subAmenities[i].temporarily_disabled = 0
            }else{
              subAmenities[i].temporarily_disabled = 1
            }
          }
        }
        this.amenityDetailsChecked = subAmenities
          .filter((option) => option.temporarily_disabled === 0)
          .map((option) => option.id_community_intent)
        this.setAmenitiesDetails(subAmenities)
    },
    unckeckAllSubAmenities(subAmenities){
      for (let i = 0; i < subAmenities.length; i++) {
        subAmenities[i].temporarily_disabled = 1
        }
      this.amenityDetailsChecked = subAmenities
        .filter((option) => option.temporarily_disabled === 0)
        .map((option) => option.id_community_intent)
      this.setAmenitiesDetails(subAmenities)
    },
    updateCheckbox(e) {
      let isIntent = e.isIntent
      let idCommunityIntent = e.target.value
      if(typeof this.intentInUse.intent_name !== "undefined"){
        if(isIntent){
          this.flipAmenityCheckbox(
          this.intentInUse,
          idCommunityIntent
          )
        }else{
          this.flipSubAmenityCheckbox(
          this.intentInUse.sub_amenities,
          idCommunityIntent
          )
        }
      }
    },
    fetchIntents(amenityName, endpointName) {
      this.setLoading(true)
      this.$dhDataProvider
        .getList(endpointName, {
          communityId: this.community?.id,
          category: amenityName,
        })
        .then((res) => {
          this.intentInUse = res.find(
            (int) =>
              int.id_community_intent === this.intentInUse?.id_community_intent
          )
          this.setAmenities(res)
          this.amenitiesChecked = res
            .filter((option) => option.temporarily_disabled === 0)
            .map((option) => option.id_community_intent)
        })
        .catch((error) => this.notifyError(error.message))
        .finally(() => {
          if (this.intentInUse){
            this.fetchSubIntents()
          }
          this.setLoading(false)
        })
    },
    fetchSubIntents() {
      if(typeof this.intentInUse.intent_name !== undefined){
        let subAmenities = this.intentInUse.sub_amenities
        this.amenityDetailsChecked = subAmenities
          .filter((option) => option.temporarily_disabled === 0)
          .map((option) => option.id_community_intent)
        this.setAmenitiesDetails(subAmenities)
      }
    },
    setCategory(amenityType) {
      this.intentInUse = null
      if (!amenityType) {
        this.cleanState('amenityType')
        this.intentInUse = null
        this.intentIdInUse = null
        this.setLoading(false)
        this.activeOption = null
        return
      }
      this.setAmenityType(amenityType)
      this.fetchIntents(amenityType?.intent_category_name,'customIntents')
    },
    setIntent(amenity) {
      if (!amenity) return
      if (this.intentInUse === amenity) {
        this.subIntentsHasChanged = false
        return
      } else {
        this.intentInUse = amenity
        this.setAmenity(amenity)
      }
      if (this.amenitiesChecked?.length > 0) {
        this.intentIdInUse = this.amenitiesChecked.find(
          (id) => amenity?.id_community_intent === id
        )
      }
      this.fetchSubIntents()
      this.activeOption = amenity?.intent_name
      this.suggestionText  = amenity?.suggestion_text
    },
    stateMachineDisableCheckbox() {
      if (this.intentInUse?.temporarily_disabled === 1) {
        return true
      }
    },
    isDefaultDisabled(amenity){
      const isDefaultAmenity = this.unitAmenitiesDisabled.includes(amenity.intent_name)
      if((amenity.temporarily_disabled == 1) && isDefaultAmenity){
        amenity.temporarily_disabled = 0
        }
      return isDefaultAmenity
    },
    checkIfCommunityIntentHasActiveSubIntents(amenity) {
      const subAmenities = amenity.sub_amenities
      var activeSubAmenities = 0
      for (let i = 0; i < subAmenities.length; i++) {
        if(subAmenities[i].temporarily_disabled == 0){
          activeSubAmenities++
          }
        }
      if(activeSubAmenities > 0){
        this.handleUncheckAmenityModal(amenity)
      }else{
        amenity.temporarily_disabled = 1
      }
    },
    onSave(e=true) {  
      if(this.loading || this.amenities.length === 0) {
        return null;
      }
      this.isDisabled = true;
      this.setLoading(true)
      this.$dhDataProvider
        .update('communityIntents', {
          id: '',
          data: {
            intents: this.amenities,
          },
        })
        .then(() => {
            if(e){
              this.notifySuccess(
                'The community amenities were updated successfully'
              )
              this.category.description == 'Property Amenities'
              ? this.$emit('currentStep', 2)
              : this.$emit('currentStep', 3)
              
            }
          }
        )
        .catch((error) => {
          this.notifyError(error.message)
        })
        .finally(() => {
          EventBus.emit('next-step');
          this.setLoading(false);
          this.isDisabled = false;
        })
    },
    previousStep(){
      this.isDisabled = true;
      this.setLoading(true)
      this.$dhDataProvider
        .update('communityIntents', {
          id: '',
          data: {
            intents: this.amenities,
          },
        })
        .then(() =>{
              this.notifySuccess(
                'The community amenities were updated successfully'
              )
          }
        )
        .catch((error) => {
          this.notifyError(error.message)
        })
        .finally(() => {
          this.$emit('previousStep')
          EventBus.emit('previous-step');
          this.setLoading(false);
          this.isDisabled = false;
        })
    }
  },
  created() {  
    this.setChangedState([])
    this.setCategory(this.category) 
    if (!this.community) {
      this.notifyError(
        'please select a community to continue, then refresh the browser'
      )
      this.setAmenities([]);
      return;  
    }
  },
  unmounted() {
    this.setAmenities([])
  }
}
</script>
<style scoped>
.section {
  width: 48%;
  padding: 0.5rem;
  padding: 0 4rem 0 4rem;
  border-color: var(--highlightColor300);
  @apply border-r-2 ;
}
.section:first-child {
  width: 50%;
  padding: 0.5rem 4rem 0rem 0.5rem;
}
.section:last-child {
  border: none;
}
.activeCategory {
  background-color: var(--highlightColor500);
  @apply text-white font-bold py-2 px-4;
}
.active {
  font-weight: bold;
}
.title {
  @apply text-blue-500;
  font-weight: bold;
}
.activeSubCat {
  border-color: var(--highlightColor500);
  @apply border-2;
}
.disabledSubCat {
  opacity: 0.5;
  cursor: default;
}
.color-button{
  background-color: var(--highlightColor500);
}
.buttonDisabled {
  cursor: not-allowed;
}
.disabledText {
  @apply text-gray-400;
}
.buttonDisabled:hover {
  color: var(--highlightColor500);
  @apply bg-transparent;
  cursor: not-allowed;
}
.icon-link {
  width: 1.1rem !important;
  height: 1.1rem !important;
  padding-top: 3px;
}
.btn-primary:hover {
  background-color: var(--highlightColor500);
  @apply text-white;
}
.activeTab {
  @apply text-blue-500;
  border-left: 4px solid lightskyblue;
  font-weight: bold;
}
.tab {
  padding: 5px;
}
</style>
