<template>
    <div>
        <steps :with-buttons="false"
               :disable-steps-navigation="true">
            <step title="general settings">
                <general-settings   v-if="currentTab == 0"
                @currentStep="selectTab"  
                >
                </general-settings>
            </step>  
             <step title="property amenities">
                  <amenities  v-if="currentTab == 1"
                    description="first, select which primary amenities are in your community. 
                    then, select the secondary details that apply to each primary amenity." 
                    :category="{
                        description: 'Property Amenities', 
                        intent_category_name: 'Property Amenities'
                    }"
                    :key="'property-amenities'"
                    @currentStep="selectTab"
                    @previousStep="previousTab"
                >
                </amenities>
               
            </step>
            <step title="unit amenities">
              <amenities  v-if="currentTab == 2"
                    description="now, select which primary amenities are in your units.  
                        then, select the secondary details that apply to each primary amenity.  
                        note that some primary unit amenities are selected automatically.  
                        for these, you are still encouraged to select the appropriate secondary amenities." 
                    :category="{
                        description: 'Unit Amenities',
                        intent_category_name: 'Unit Amenities'
                    }"
                    :key="'unit-amenities'"
                    @currentStep="selectTab"
                    @previousStep="previousTab"
                >
                </amenities>
            </step>
            <step title="fees">
                <fees v-if="currentTab == 3"
                @previousStep="previousTab">
                </fees>
            </step>
        </steps>
    </div>
</template>

<script>
    import Auth from "@/mixins/Auth";
    import Step from "@/components/ui/steps/Step";
    import Steps from "@/components/ui/steps/Steps";
    import ModalNavigation from '@/mixins/ModalNavigation';
    import GeneralSettings from '@/views/digital_human/setup_wizard/GeneralSettings'
    import Amenities from '@/views/digital_human/setup_wizard/Amenities'
    import Fees from '@/views/digital_human/setup_wizard/Fees'

    export default {
        components: { Steps, Step, Fees, GeneralSettings, Amenities },
        mixins: [ModalNavigation, Auth],
         data() {
            return {
                currentTab: 0,
            }
        },
        methods: {
            selectTab(nextTab) {
                if (this.currentTab !== nextTab) {
                    this.currentTab = nextTab;
                }
            },
            previousTab() {
                if (this.currentTab > 0) {
                    this.currentTab = this.currentTab - 1;
                }
            }
        },
        created() {
            this.selectTab(0);
        },
    }
</script>
